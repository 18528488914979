/* NOTE: the order of these styles DO matter */

.DayPicker {
  @apply mx-auto;
}

.DayPickerNavigation_button {
  @apply border-0;
}

.DayPickerNavigation_button:hover {
  @apply border-0;
}

.CalendarDay {
  @apply border-0;
}

.CalendarDay:hover {
  @apply text-black bg-white border-0;
}

.CalendarDay__blocked_calendar {
  @apply bg-white;
}

/* Will edit everything selected including everything between a range of dates */
.CalendarDay__selected_span {
  @apply bg-gray-200 border-0 text-black;
}

/* Will edit selected date or the endpoints of a range of dates */
.CalendarDay__selected {
  @apply bg-yellow-500 text-white;
}

/* Will edit when hovered over. _span style also has this property */
.CalendarDay__selected:hover {
  @apply bg-yellow-500 text-white;
}

/* Will edit when the second date (end date) in a range of dates
is not yet selected. Edits the dates between your mouse and said date */
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  @apply text-black bg-gray-200 border-0;
}
