.multi-range-slider_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.multi-range-slider_slider {
  position: relative;
  width: 100%;
}

.multi-range-slider_slider__track,
.multi-range-slider_slider__range,
.multi-range-slider_slider__left-value,
.multi-range-slider_slider__right-value {
  position: absolute;
}

.multi-range-slider_slider__track,
.multi-range-slider_slider__range {
  border-radius: 8px;
  height: 8px;
}

.multi-range-slider_slider__track {
  background-color: #eaeaea;
  width: 100%;
  z-index: 1;
}

.multi-range-slider_slider__range {
  background-color: #98bfe0;
  z-index: 2;
}

.multi-range-slider_slider__thumb--left {
}

/* Removing the default appearance */
.multi-range-slider_thumb,
.multi-range-slider_thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.multi-range-slider_thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 90%;
  outline: none;
}

.multi-range-slider_thumb--left {
  z-index: 3;
}

.multi-range-slider_thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.multi-range-slider_thumb::-webkit-slider-thumb {
  background-color: white;
  border: 1px solid #c4c4c4;
  border-radius: 50%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  height: 24px;
  width: 24px;
  margin-top: 6px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.multi-range-slider_thumb::-moz-range-thumb {
  background-color: white;
  border: 1px solid #c4c4c4;
  border-radius: 50%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  height: 24px;
  width: 24px;
  margin-top: 6px;
  pointer-events: all;
  position: relative;
}

/* For IE browsers */
.multi-range-slider_thumb::-ms-thumb {
  background-color: white;
  border: 1px solid #c4c4c4;
  border-radius: 50%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  height: 24px;
  width: 24px;
  margin-top: 6px;
  pointer-events: all;
  position: relative;
}
