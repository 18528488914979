@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    -webkit-tap-highlight-color: transparent;
  }

  html,
  body,
  #__next {
    @apply h-full min-h-screen;
  }

  @font-face {
    font-family: 'CooperBT';
    font-weight: 500;
    src: url('/fonts/CooperBT-Medium/3AEA7B_0_0.eot');
    src: url('/fonts/CooperBT-Medium/3AEA7B_0_0.eot?#iefix') format('embedded-opentype'),
      url('/fonts/CooperBT-Medium/3AEA7B_0_0.woff2') format('woff2'),
      url('/fonts/CooperBT-Medium/3AEA7B_0_0.woff') format('woff'),
      url('/fonts/CooperBT-Medium/3AEA7B_0_0.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Larsseit';
    font-weight: 400;
    src: url('/fonts/Larsseit-Regular/font.woff2') format('woff2'),
      url('/fonts/Larsseit-Regular/font.woff') format('woff');
  }

  @font-face {
    font-family: 'Larsseit';
    font-weight: 500;
    src: url('/fonts/Larsseit-Medium/3AEA7B_1_0.eot');
    src: url('/fonts/Larsseit-Medium/3AEA7B_1_0.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Larsseit-Medium/3AEA7B_1_0.woff2') format('woff2'),
      url('/fonts/Larsseit-Medium/3AEA7B_1_0.woff') format('woff'),
      url('/fonts/Larsseit-Medium/3AEA7B_1_0.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Larsseit';
    font-weight: 300;
    src: url('/fonts/Larsseit-Light/font.woff2') format('woff2'),
      url('/fonts/Larsseit-Light/font.woff') format('woff');
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

@layer components {
  .pin-field {
    appearance: textfield;
    @apply w-1/6 rounded-none border border-solid border-gray-400 border-l-0 h-14;
    @apply text-center font-sans text-3xl text-black;
    @apply p-0 first:border-l first:rounded-l-lg last:rounded-r-lg;
    @apply focus:outline-none transform focus:scale-105 focus:shadow;
    max-width: 3.375rem;
  }

  .content-section {
    @apply border-0 lg:border-b border-gray-200 py-4 lg:pb-12 lg:pt-8 last:border-b-0 last:pb-0;
  }

  .content-section-with-border {
    @apply lg:border-b lg:pb-12 !important;
  }

  .content-header {
    @apply text-2xl font-medium font-serif;
  }

  .content-section-header {
    @apply mb-5 text-xl font-medium font-serif;
  }

  .content-field-label {
    @apply mb-3.5 text-base font-light font-sans;
  }

  .content-field {
    @apply py-4 px-6 rounded-2xl bg-gray-200 font-medium outline-none focus:border-gray-400;
  }

  .content-field-light {
    @apply focus:border-gray-600 field form-field font-sans font-light text-base text-black;
    @apply block py-4 px-5 rounded-2xl bg-transparent border-gray-400;
  }

  .btn-sec {
    @apply p-3 text-black bg-gray-200;
  }

  .btn-sec:hover,
  .btn-sec:focus {
    @apply bg-gray-400;
  }

  .btn-sec:active {
    @apply bg-gray-300;
  }

  .btn-sec:disabled {
    @apply cursor-default bg-gray-600;
  }

  .btn-outlined {
    @apply p-3 text-black bg-white border border-gray-400 rounded-2xl;
  }

  .btn-outlined:hover,
  .btn-outlined:focus {
    @apply bg-gray-400 outline-none;
  }

  .btn-outlined:active {
    @apply outline-none;
    @apply bg-gray-300;
  }

  .btn-outlined:disabled {
    @apply cursor-default bg-gray-600;
  }

  .cta {
    @apply text-black text-lg bg-yellow-500;
  }

  .cta:hover,
  .cta:focus {
    @apply bg-yellow-600;
  }

  .cta:disabled {
    @apply cursor-default bg-gray-600;
  }

  .cta:active {
    @apply bg-yellow-100;
  }

  .topbar-button {
    @apply inline-flex items-center font-medium px-4 py-3 pr-5 cursor-pointer;
    @apply whitespace-nowrap border border-transparent rounded-2xl;
    @apply hover:text-gray-400 hover:bg-gray-300 hover:bg-opacity-20;
  }

  .topbar-button-active {
    @apply bg-gray-200 text-black hover:text-black hover:bg-gray-200 hover:bg-opacity-100;
  }

  .topbar-button-light {
    @apply hover:text-gray-800 hover:bg-gray-200;
  }

  .google-button {
    background-color: #cf4332;
  }

  .google-button:hover {
    background-color: #d53d3d;
  }

  .google-button:disabled {
    @apply bg-gray-600 cursor-not-allowed;
  }

  .facebook-button {
    background-color: #3c66c4;
  }

  .facebook-button:hover {
    background-color: #2c58ba;
  }

  .facebook-button:disabled {
    @apply bg-gray-600 cursor-not-allowed;
  }

  .placeholder-font-light::placeholder {
    @apply font-light md: font-medium;
  }
}

@layer utilities {
  .scrolling-touch {
    -webkit-overflow-scrolling: touch;
  }

  .scrolling-auto {
    -webkit-overflow-scrolling: auto;
  }
}
