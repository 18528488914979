.mapboxgl-popup {
  /* @apply hover:z-20 !important; */
}

.mapboxgl-popup-content {
  /* @apply rounded-xl border-2 border-gray-400 hover:border-gray-500 !important; */
  /* @apply p-0 !important; */

  @apply p-0 bg-none bg-transparent shadow-none rounded-none !important;
}
